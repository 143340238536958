import React, { useEffect } from "react"
import { graphql } from "gatsby"

import { QueryFragments } from "../GraphQl/queryFragments" // eslint-disable-line

// Hooks, Querys & State
import { useAppState } from "../state/appState"
import { PageMeta } from "../components/pageMeta"
import BlockZone from "~blockZone"
import { HeroBanner } from '../components/blockZone/blocks'

const IndexPage = ({ data: { page } }) => {
  const { meta, pageBlocks, banner, darkHeader } = page || {};

  const { toggleHeaderLight, setHideFooter } = useAppState();

  useEffect(() => {
    if (darkHeader === true) {
      toggleHeaderLight(false);
    } else {
      toggleHeaderLight(true);
    }
  }, [toggleHeaderLight, darkHeader]);
  
  useEffect(() => {    
    setHideFooter(false);
  }, [setHideFooter]);

  return (
    <>
      <PageMeta {...meta} />
      <HeroBanner {...banner} />
      {pageBlocks && <BlockZone {...pageBlocks} />}
    </>
  );
}

export default IndexPage

export const pageQuery = graphql`
  query homePageQuery {
    page: sanityHomePage {
      banner {
        heading
        rotatingWords
        links {
          ... on SanityPageLink {
            _type
            ...PageLinkFields
          }
          ... on SanityPathLink {
            _type
            ...PathLinkFields
          }
          ... on SanityExternalLink {
            _type
            ...ExternalLinkFields
          }
          ... on SanityPageAnchorLink {
            _type
            ...PageAnchorLink
          }
        }
        video
        image {
          ...ImageWithPreview
        }
      }
      darkHeader
      meta {
        ...MetaCardFields
      }
      pageBlocks {
        ...BlockZone
      }
    }
  }
`;
